
  import React from 'react';
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor/runtime/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/work/338eadb1c81287ab/wix-events-statics/events-viewer/src/components/members-page/Widget/index.tsx';

  import {
    withLeanStyles
  } from '@wix/native-components-infra';

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  
import { createExperiments, createWidgetExperiments } from '@wix/yoshi-flow-editor/runtime/esm/experiments';
    

  
import { I18nextProvider } from '@wix/yoshi-flow-editor/i18n';


      import { PureExperimentsProvider } from '@wix/yoshi-flow-editor';
      var ExperimentsProvider = React.Fragment;
      

import { BILoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/BILogger/BILoggerProvider';

import { FedopsLoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/FedopsLoggerProvider';

import { HttpProvider } from '@wix/yoshi-flow-editor';

import { TPAComponentsProvider } from 'wix-ui-tpa';

import { BaseUiEnvironmentProviderWrapper } from '@wix/yoshi-flow-editor/runtime/esm/react/BaseUiEnvironmentProviderWrapper';

var providers = {
  I18nextProvider,
  ExperimentsProvider,
  PureExperimentsProvider,
  BILoggerProvider,
  FedopsLoggerProvider,
  HttpProvider,
  TPAComponentsProvider,
  BaseUiEnvironmentProviderWrapper,
}


  

    import * as usersStyleParamsEntry from '/home/builduser/work/338eadb1c81287ab/wix-events-statics/events-viewer/src/components/members-page/stylesParams.ts';
var stylesParamsEntry = usersStyleParamsEntry;
var stylesParams = stylesParamsEntry.default;
var customCssVars = stylesParamsEntry.customCssVars || function() { return {} };
    


  var styleHocConfig = {"enabled":true};

  var sentryConfig = {
      DSN: 'https://88170cb0c9d64f94b5821ca7fd2d55a4@sentry-next.wixpress.com/860',
      id: 'f1ffc0b5efe04e9eb9762cd808722520',
      projectName: 'events-viewer',
      teamName: 'events',
      errorMonitor: true,
    };

  var translationsConfig = {"prefix":"messages","icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/338eadb1c81287ab/wix-events-statics/events-viewer/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var UserComponent = getWidgetWrapper({
      initI18n,
      withStylesHoc: withLeanStyles,
      createExperiments,
      createWidgetExperiments,
      providers,
    }, Widget, {
    multilingualDisabled,
    sentryConfig,
    styleHocConfig,
    translationsConfig,
    stylesParams,
    customCssVars,
    componentId: '405eb115-a694-4e2b-abaa-e4762808bb93',
    name: 'members-page',
    withErrorBoundary: false,
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    
          loadableReady: process.env.browser ? require("@wix/yoshi-flow-editor/loadable").loadableReady : null,
          chunkLoadingGlobal: process.env.chunkLoadingGlobal,
          
    component: UserComponent,
    loadChunks
  };
